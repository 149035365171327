import React from 'react'
import { FirebaseAppProvider } from 'reactfire';

import FirebaseSDK from './sdk'

const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG);

const Provider = ({ children }) => {
  if (typeof window === 'undefined') return children
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseSDK>
        {children}
      </FirebaseSDK>
    </FirebaseAppProvider>
  )
}

export default Provider
