import React, { cloneElement, createElement, useRef, useState } from 'react';
import {
  FirestoreProvider,
  FunctionsProvider,
  AuthProvider,
  StorageProvider,
  useFirebaseApp
} from 'reactfire';

import firebaseContext from './context';

const getProviders = {
  firestore: async () =>
    Promise.all([
      FirestoreProvider,
      import('firebase/firestore').then(
        ({ getFirestore, connectFirestoreEmulator }) => ({
          getter: getFirestore,
          connectEmulator: connectFirestoreEmulator
        })
      ),
      ['localhost', 8080]
    ]),
  functions: async () =>
    Promise.all([
      FunctionsProvider,
      import('firebase/functions').then(
        ({ getFunctions, connectFunctionsEmulator }) => ({
          getter: (app) => getFunctions(app, 'asia-east2'),
          connectEmulator: connectFunctionsEmulator
        })
      ),
      ['localhost', 5001]
    ]),
  auth: async () =>
    Promise.all([
      AuthProvider,
      import('firebase/auth').then(({ getAuth, connectAuthEmulator }) => ({
        getter: getAuth,
        connectEmulator: connectAuthEmulator
      })),
      ['http://localhost:9099']
    ]),
  storage: async () =>
    Promise.all([
      StorageProvider,
      import('firebase/storage').then(
        ({ getStorage, connectStorageEmulator }) => ({
          getter: getStorage,
          connectEmulator: connectStorageEmulator
        })
      ),
      ['localhost', 9199]
    ])
};

const FirebaseSDK = ({ children }) => {
  const initedProviders = useRef({});
  const loadedSDK = useRef({});
  const [activeSDK, setActiveSDK] = useState([]);
  const app = useFirebaseApp();
  const handleAddSdk = async (sdk) => {
    const arrSdk = [].concat(sdk).filter((s) => !activeSDK.includes(s));
    if (arrSdk.length) {
      await Promise.all(
        arrSdk.map(async (s) => {
          loadedSDK.current[s] = await getProviders[s]();
        })
      );
      setActiveSDK([...activeSDK, ...arrSdk]);
    }
  };
  return (
    <firebaseContext.Provider value={{ addSdk: handleAddSdk, activeSDK }}>
      {activeSDK.reduce((ele, sdk) => {
        if (!initedProviders.current[sdk]) {
          const [
            Provider,
            { getter, connectEmulator },
            emulatorProps
          ] = loadedSDK.current[sdk];
          const thisSdk = getter(app);
          if (
            typeof connectEmulator === 'function' &&
            process.env.NEXT_PUBLIC_FIREBASE_EMULATOR &&
            process.env.NODE_ENV !== 'production'
          ) {
            try {
              connectEmulator(thisSdk, ...emulatorProps);
            } catch (e) {
              console.error(e);
            }
          }
          initedProviders.current[sdk] = createElement(Provider, {
            sdk: thisSdk
          });
        }

        return cloneElement(initedProviders.current[sdk], {}, ele);
      }, children)}
    </firebaseContext.Provider>
  );
};

export default FirebaseSDK;
